<template>
    <div>
        <v-hover
            v-slot="{ props: hoverProps }"
            v-model="isHovered"
        >
            <v-card
                v-if="housing"
                v-bind="hoverProps"
                class="w-100 h-100 pa-0 housing-card"
                style="box-sizing: border-box; transition-property: border-width; transition-duration: 50ms;"
                :elevation="isHovered ? 2 : 0"
            >
                <template #image>
                    <nuxt-link
                        class="d-md-none"
                        :to="housingPage"
                    >
                        <img
                            v-if="housing?.Media && housing?.Media[0]?.MediaURL"
                            :src="$img(housing.Media[0].MediaURL, { height: 240, quality: 90, format: 'webp' })"
                            :alt="housing.Media[0].ShortDescription"
                            height="240"
                            cover
                        />
                    </nuxt-link>
                    <VCarousel
                        ref="carousel"
                        v-model="currentImage"
                        hide-delimiters
                        :cycle="isHovered"
                        show-arrows="hover"
                        style="width: 100% important;"
                        height="240"
                        interval="2500"
                        class="d-none d-md-block"
                    >
                        <template #prev="{ props: btnProps }">
                            <v-scroll-x-transition>
                                <v-btn
                                    v-if="isHovered"
                                    v-bind="hoverProps"
                                    icon="chevron-left"
                                    variant="flat"
                                    color="#0000000f"
                                    class="text-white rounded-circle"
                                    @click="btnProps.onClick"
                                />
                            </v-scroll-x-transition>
                        </template>
                        <template #next="{ props: btnProps }">
                            <v-scroll-x-reverse-transition>
                                <v-btn
                                    v-if="isHovered"
                                    v-bind="hoverProps"
                                    icon="chevron-right"
                                    variant="flat"
                                    color="#0000000f"
                                    class="text-white rounded-circle"
                                    @click="btnProps.onClick"
                                />
                            </v-scroll-x-reverse-transition>
                        </template>
                        <v-carousel-item
                            v-for="media, i in housing!.Media"
                            :key="i"
                        >
                            <v-lazy style="height: 240px">
                                <nuxt-link
                                    class="text-black"
                                    :to="housingPage"
                                    style="text-decoration: none;"
                                >
                                    <v-img
                                        v-bind="hoverProps"
                                        :src="$img(media.MediaURL, { height: 240, quality: 90, format: 'webp' })"
                                        :lazy-src="$img(media.MediaURL, { height: 120, quality: 40, format: 'webp' })"
                                        :alt="media.ShortDescription"
                                        height="240"
                                        cover
                                    />
                                </nuxt-link>
                            </v-lazy>
                        </v-carousel-item>
                    </VCarousel>
                    <v-expand-transition class="d-none d-md-block">
                        <v-progress-linear
                            v-if="isHovered"
                            v-model="currentImage"
                            color="primary"
                            :max="housing!.Media.length - 1"
                            v-bind="hoverProps"
                        />
                        <div
                            v-else
                            style="height: 4px"
                        />
                    </v-expand-transition>

                </template>

                <template #title>
                    <nuxt-link
                        :to="housingPage"
                        style="text-decoration: none;"
                    >
                        <h2 v-text="buildingAdress" class="text-black h-100 text-h6 text-wrap"/>
                    </nuxt-link>
                </template>

                <!-- <template #subtitle>
                    <nuxt-link
                        :to="housingPage"
                        style="text-decoration: none;"
                    >
                        <h3 v-text="buildingName" class="text-black h-100 text-body-1 text-truncate"/>
                    </nuxt-link>
                </template> -->

                <template #text>
                    <nuxt-link
                        class="text-black h-100"
                        :to="housingPage"
                        style="text-decoration: none;"
                    >
                        <v-divider thickness="2" />
                        <dynamic-grid
                            class="py-3 text-regular housing-stats"
                            :columns="2"
                            :items="displayItems"
                            :gap="2"
                        >
                            <template #item="{ icon, text }">
                                <div style="align-self: center;">
                                    <font-awesome-icon
                                        class="me-2"
                                        style="line-height: 1rem; vertical-align: middle;"
                                        :icon="icon"
                                    />
                                    <span
                                        style="line-height: 1rem; vertical-align: middle;"
                                        v-text="text"
                                    />
                                </div>
                            </template>
                        </dynamic-grid>
                        <v-divider thickness="2" />
                    </nuxt-link>
                </template>

                <template #actions>
                    <span
                        class="text-h6 text-primary mx-2 my-auto"
                        v-text="Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(housing?.ListPrice ?? 0)"
                    />
                    <span
                        v-if="housing?.ListOldPrice"
                        class="text-h7 text-primary mx-2 my-auto text-decoration-line-through"
                        v-text="Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(housing?.ListOldPrice)"
                    />
                    <!--
          <v-btn disabled icon>
            <font-awesome-icon :icon="['far', 'heart']" size="xl" />
          </v-btn>
          <v-btn disabled icon>
            <font-awesome-icon :icon="['fa', 'share']" size="xl" />
          </v-btn>
          -->
                </template>
            </v-card>
        </v-hover>
    </div>
</template>

<script setup lang="ts">
import { VCarousel } from 'vuetify/lib/components/VCarousel/index.mjs'

const props = defineProps<{ housing: ListingModel | null }>()
const isHovered = ref(false)
const carousel = ref<VCarousel>()
const currentImage = ref(0)

const buildingName = computed(() => props.housing?.Property.Structure.BuildingName)

const cardTitle = computed(() => {
    if (props.housing) {
        const { StreetName, StreetNumber } = props.housing.Property.Location
        return buildingName.value || `${StreetName} ${StreetNumber}`
    }
    return '[No Address]'
})

const buildingAdress = computed(() => {
    if (props.housing) {
        const { StreetNumber, StreetName, City, StateOrProvince, PostalCode } = props.housing.Property.Location

        return `${StreetNumber} ${StreetName}, ${City}, ${StateOrProvince.slice(0, 2).toUpperCase()} ${PostalCode}`
    }

    return 'No Address'
})

const housingPage = computed(() => {
    if (props.housing) {
        const { PropertyType, Location, PropertySubType } = props.housing.Property
        return `/buy/${PropertyType}/${Location.City}/${PropertySubType.replaceAll('/', '+')}/${props.housing.Slug}`.replaceAll(' ', '+')
    }
    return undefined
})

interface DisplayedItem {
    icon: string[]
    text: string
}

const displayItems = computed(() => [
    props.housing?.Property.Structure.BedroomsTotal && { icon: ['fa', 'bed'], text: `${props.housing!.Property.Structure.BedroomsTotal} Beds` },
    props.housing?.Property.Structure.BathroomsFull && { icon: ['fa', 'bath'], text: `${props.housing!.Property.Structure.BathroomsFull} Baths` },
    props.housing?.Property.Structure.LivingArea && { icon: ['fa', 'ruler-combined'], text: `${props.housing!.Property.Structure.LivingArea} SqFt` },
    props.housing?.Property.Structure.BathroomsHalf && { icon: ['fa', 'sink'], text: `${props.housing!.Property.Structure.BathroomsHalf} HBaths` },
].filter(x => !!x) as DisplayedItem[])

watch(isHovered, () => {
    carousel.value?.$forceUpdate()
})
</script>

<style lang="scss">
.v-skeleton-loader__image {
    height: 240px;
}

.housing-card>.v-card__image {
    display: block;
    height: fit-content;
    position: relative;
    z-index: 0;
}

.housing-card .housing-stats {
    height: 80px;
}

.housing-card h2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: calc((1em *1.6)*2);
}
</style>
